<template>
	<div class="ng-content-wrap">
		<div class="ng-block-head ng-block-head-lg">
			<div class="ng-block-head-sub d-print-none">
				<router-link class="back-to" to="/">
					<em class="icon ni ni-chevron-left-c mr-1"></em>
					<span>Dashboard</span>
				</router-link>
			</div>
			<div class="ng-block-between-md g-4">
				<div class="ng-block-head-content">
					<h2 class="ng-block-title fw-normal">
						{{ $route.meta.title }}
					</h2>
					<div class="ng-block-des d-print-none">
						<p>{{ product?.name }}</p>
					</div>
				</div>
				<div class="ng-block-head-content">
					<ul class="ng-block-tools gx-3 d-print-none">
						<li>
							<div class="input-group">
								<div
									class="input-group-prepend d-none d-sm-flex"
								>
									<span
										class="input-group-text bg-primary text-white font-weight-bold"
									>
										Product
									</span>
								</div>
								<Multiselect
									:options="products"
									v-model="product_id"
									trackBy="name"
									label="name"
									valueProp="id"
									:searchable="true"
									placeholder="Start typing..."
									@change="fetchHistory"
								/>
							</div>
						</li>
						<li>
							<router-link
								to="/calculate"
								data-toggle="modal"
								class="btn btn-white btn-dim btn-outline-primary"
							>
								<em class="icon ni ni-plus-c"></em>
								<span>
									New
									<span class="d-none d-sm-inline-block">
										Calculation
									</span>
								</span>
							</router-link>
						</li>
					</ul>
				</div>
			</div>
		</div>
		<div class="ng-block">
			<div class="card card-bordered loading" v-if="processing">
				<div class="justify-content-center text-center">
					<div class="spinner-grow text-primary m-3" role="status">
						<span class="sr-only">Processing Data...</span>
					</div>
					<h6 class="mb-4">Processing Data...</h6>
				</div>
			</div>

			<div class="card card-bordered" v-else>
				<data-table
					:headers="headers"
					:records="records.data"
					@delete="deleteRecord"
				>
					<template #title="scope">
						{{ scope.record?.product?.name || "View Calculation" }}
					</template>

					<template #details="scope">
						<button
							@click.prevent="viewCalculation(scope.record)"
							class="btn btn-primary"
						>
							View Calculation
						</button>
					</template>

					<template #form="scope">
						<form
							class="form row"
							@submit.prevent="updateRecord(scope.record)"
						>
							<div class="form-group col-md-6">
								<label>
									Select Product
								</label>
								<Multiselect
									:options="products"
									v-model="scope.record.product_id"
									trackBy="name"
									label="name"
									valueProp="id"
									:searchable="true"
									placeholder="Select Item"
								/>
							</div>
							<div class="form-group col-md-6">
								<label>Target</label>
								<input
									class="form-control"
									v-model="scope.record.target"
								/>
							</div>
							<div class="form-group col-md-6">
								<label>Loose</label>
								<input
									class="form-control"
									v-model="scope.record.loose"
								/>
							</div>
							<div class="form-group col-md-6">
								<label>Pre-Pack</label>
								<input
									class="form-control"
									v-model="scope.record.prepack"
								/>
							</div>
							<div class="form-group col-md-12">
								<button class="btn btn-primary float-right">
									<em class="icon ni ni-save"></em>
									<span>
										Update {{ scope.record.name }}
									</span>
								</button>
							</div>
						</form>
					</template>
				</data-table>
			</div>

			<paginate
				:data="records"
				@changed="fetchRecords"
				v-if="records.data.length"
			/>
		</div>
	</div>
</template>

<script lang="ts">
	import http from "../../../../common/services/http";
	import { defineComponent, ref, reactive, onMounted, computed } from "vue";
	import router from "../router";

	export default defineComponent({
		setup(props) {
			const headers = {
				user: "Customer",
				kg: "Per Kg",
				box: "Per Box",
			};

			const records = ref({
				data: [],
			});


			const processing = ref(false);
			const updated = ref(false);
			const products = ref([]);
			const product_id = ref(0);
			const product = computed(() => {
				return (
					products.value?.find(
						(c: any) => c.id == product_id.value
					) || { contact: { currency: "euro" } }
				);
			});

			function fetchproducts() {
				processing.value = true;

				http.get(`/api/products?fetch=all`).then((res) => {
					products.value = res.data;
					processing.value = false;
				});
			}

			function fetchRecords(page = 1) {
				processing.value = true;

				http.get(
					`/api/calculations?product_id=${product_id.value}&page=${page}`
				).then((res) => {
					records.value = res.data;
					processing.value = false;
				});
			}

			function fetchHistory(p: any) {
				processing.value = true;

				http.get(
					`/api/calculations?product_id=${p}`
				).then((res) => {
					records.value = res.data;
					processing.value = false;
				});
			}

			function updateRecord(record: any) {
				record._method = 'PUT'
				http.post(`/api/calculations/${record.id}`, record)
					.then((res) => {
						if (res.data.success) {
							updated.value = true;
						}
					})
					.finally(() => {
						document.getElementById("hideEditForm")?.click();
						fetchRecords();
					});
			}

			function deleteRecord(id: any) {
				http.post(`/api/calculations/${id}`, { _method: "DELETE" }).then((res) => {
					fetchRecords();
				});
			}

			function viewCalculation(data: any) {
				document.getElementById("hideView")?.click();
				router.replace(`/calculations/${data.id}`);
			}

			onMounted(() => {
				fetchproducts();
			});

			return {
				headers,
				fetchRecords,
                fetchHistory,
				processing,
				records,
				updateRecord,
				updated,
				deleteRecord,
				products,
				product_id,
				product,
				viewCalculation,
			};
		},
	});
</script>
